import { post, postV2 } from "./apiManager";

export const placeAppointment = (body, onSuccess, onFailed, onComplete) => {
  post(
    "Appointment",
    body,
    "placeAppointment",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const getAppointments = (
  PatientMobile,
  onSuccess = () => { },
  onFailed = () => { },
  onComplete = () => { }
) => {
  fetch(
    `${process.env.REACT_APP_MEDICA_URL}AppointmentPrescriptionRecord/GetAppointment`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: 2,
      },
      body: JSON.stringify({
        PatientMobile,
      }),
    }
  )
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    })
    .finally(() => {
      onComplete();
    });
};

export const createAppointments = async (data) => {
  const response = await postV2({
    path: 'appointments/appointment-save-or-update',
    requestBody: {
      Description: data.Description,
      PatientId: data.PatientId,
      SessionId: data.SessionId,
      Status: data.Status,
      UserId: data.UserId,
      Id: 0,
      Number: data.Number,
      IsFollowUp: data.IsFollowUp,
      EnableAutoBillGenerate: 0,
    },
  });

  return response;
}