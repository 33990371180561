import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
    name: "service",
    initialState: {},
    reducers: {
        setService: (state, action) => {
            return action.payload;
        },
    },
});

export const { setService } = serviceSlice.actions;
export default serviceSlice.reducer;