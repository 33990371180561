import { postClinic, postV2 } from "./apiManager";

export const postBillData = async (data) => {
    const response = await postClinic({
        path: "/Bill/Post",
        requestBody: {
            AppointmentId: data.AppointmentId,
            AppointmentNumber: data.AppointmentNumber,
            BillData: data.BillData,
            Discount: data.Discount,
            Id: data.Id,
            Total: data.Total,
        },
    });

    return response;
}

export const saveBillData = async (data) => {
    const response = await postV2({
        path: '/bill-data/save',
        requestBody: data,
    });

    return response;
}