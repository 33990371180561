import { setLoadingStatusFor } from "../../reducers/loadersSlice";
import { store } from "../store";
import { get, getV2, post, postAsyncV2 } from "./apiManager";

export const getAllDoctors = async (onSuccess, onFailed) => {
  await get(`Doctor/Get/`, onSuccess, onFailed);
};

export const getAllDoctorsByInstitute = (onSuccess, onFailed) => {
  post(
    `Doctor/GetByInstitute/`,
    {
      InstituteId: 21204,
    },
    "",
    onSuccess,
    onFailed
  );
};

export const getDoctorServices = async ({ branchId, doctorId }) => {
  const response = await getV2({
    path: `/doctor-service-fee/get/?&instituteBranchId=${branchId}&doctorId=${doctorId}`,
  });
  return response;
}

export const getAllDoctorsByInstituteV2 = async (body) => {
  const response = await fetch(
    "https://testapi.doctoronline.lk/medica/clinic/api/v2/sessions/sessions-todayv2",
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        UserId: "2",
      },
      body: JSON.stringify(body),
    }
  );
  const fetched = await response.json();
  return fetched.data;
};

export const getDoctor = async (doctorId, onSuccess, onFailed) => {
  await get(`Doctor/Get/${doctorId}`, onSuccess, onFailed);
};

export const getDoctorPhoneNumber = (doctorId, onSuccess, onFailed) => {
  post(
    `DoctorContactNumber/Get/`,
    {
      DoctorId: doctorId,
    },
    "doctorPhoneNumber",
    onSuccess,
    onFailed
  );
};

export const getDoctorPhoneNumberV2 = async (doctorId) => {
  const response = await postAsyncV2("DoctorContactNumber/Get/", {
    DoctorId: doctorId,
  });
  const data = await response.json();
  return data?.Data?.[0]?.ContactNumber;
};

export const getDoctorSpecialization = (doctorId, onSuccess, onFailed) => {
  post(
    `DoctorSpecialization/Get/`,
    {
      DoctorId: doctorId,
    },
    "specialization",
    onSuccess,
    onFailed
  );
};

export const getDoctorSessions = (
  doctorId,
  selectedDate,
  type,
  onSuccess,
  onFailed
) => {
  post(
    `Session`,
    {
      DoctorId: doctorId,
      SelectedDate: selectedDate,
      Type: type,
    },
    "sessions",
    onSuccess,
    onFailed
  );
};

export const getDoctorSessionDetails = (
  sessionId,
  type,
  onSuccess,
  onFailed
) => {
  post(
    `Session`,
    {
      DoctorId: sessionId,
      Type: type,
    },
    "sessionDetails",
    onSuccess,
    onFailed
  );
};


export const getDoctorSessionCategoryV2 = async (body) => {
  const response = await fetch(
    "https://testapi.doctoronline.lk/medica/clinic/api/v2/doctor/get-doctor-session-categories",
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    }

  )
  const fetched = await response.json();
  return fetched.data;
};

